@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.row {
    display: -ms-flexbox; /* IE 10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE 10 */
    flex-wrap: wrap;
    padding: 0 4px;
    margin: 0 20px;
}

.column {
    -ms-flex: 50%;
    flex: 50%;

    padding: 0 4px;
}

.column img {
    margin-top: 8px;
    vertical-align: middle;
}

@media only screen and (max-width: 600px) {
    .column {
        -ms-flex: 100%;
        flex: 100%;
    }
}

@media screen and (max-width: 992px) and (min-width: 600px) {
    .column {
        -ms-flex: 50%;
        flex: 50%;
    }
}

@media screen and (min-width: 1000px) {
    .column {
        -ms-flex: 25%;
        flex: 25%;
    }
}
